<template>
    <ul class="list-unstyled pl-100">
        <li>
            <ds-link to="/organisms/carousel"> Carousel </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/cta-banner"> CTA banner </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/cta-card"> CTA card </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/error-page"> Error page </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/file-preview-modal"> File preview modal </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/footer"> Footer </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/form"> Form </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/nav-bar"> Nav bar </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/reviews-io-card-carousel"> Reviews.io card carousel </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/support-card"> Support card </ds-link>
        </li>
        <li>
            <ds-link to="/organisms/zip-code-form"> Zip code form </ds-link>
        </li>
    </ul>
</template>
